<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'ShortUrlsList',
              }"
            >短網址管理
            </b-breadcrumb-item
            >
            <b-breadcrumb-item active>短網址列表</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-4 mb-2 font-weight-bold">短網址列表</h4>
        <div
          class="
            col-12 col-xl-6
            d-flex
            flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <b-button
            class="flex-shrink-0 mb-2 mb-xl-0 mr-3"
            variant="primary"
            v-if="checkPermission([consts.SHORT_URL_CREATE])"
            :to="{ name: 'ShortUrlCreate' }"
          ><i class="fa fa-plus"></i>新增短網址</b-button
          >
          <b-input-group>
            <b-form-input v-model="keyword" placeholder="搜尋"></b-form-input>
            <b-input-group-append>
              <b-button @click="getShortUrls"><i class="fa fa-search"></i></b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="keywords"
                :fields="fields"
              >
                <template #cell(code)="data">
                  <a target="_blank" :href="`${shortUrlHost}/${data.item.code}`">{{`${shortUrlHost}/${data.item.code}`}}</a>
                  <i class="fa fa-copy ml-2 copy" @click="clipboard(`${shortUrlHost}/${data.item.code}`)"></i>
                  <i class="fa fa-qrcode ml-2 qrcode " :id="`qrcode-${data.item.code}`"></i>
                  <b-popover :target="`qrcode-${data.item.code}`" triggers="click" >
                    <vue-qr :text="`${shortUrlHost}/${data.item.code}`"></vue-qr>
                  </b-popover>
                </template>
                <template #cell(url)="data">
                  <div>
                    {{data.item.url_type == 'oa'? '官方帳號': data.item.url && data.item.url.slice(0, 40) + '...'}}
                  </div>
                </template>
                <template #cell(actions)="data">
                  <b-button
                    variant="inverse-primary"
                    :to="{
                      name: 'ShortUrlView',
                      params: { short_url_id: data.item.id },
                    }"
                  >
                    查看
                  </b-button>
                  <b-button
                    variant="inverse-warning"
                    :to="{
                      name: 'ShortUrlEdit',
                      params: { short_url_id: data.item.id },
                    }"
                  >
                    編輯
                  </b-button>
                  <b-button
                    variant="inverse-danger"
                    @click="deleteShortUrls(data.item)"
                  >
                    刪除
                  </b-button>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
        <div class="d-flex justify-content-center" style="margin-top: 80px">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
          ></b-pagination>
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { format } from "date-fns";
import {mapState} from "vuex";
import shortUrlApi from "@/apis/short-url";
import PermissionChecker from '@/utils/PermissionChecker'
import * as consts from '@/consts'
import VueQr from 'vue-qr'

export default {
  components: {
    VueQr,
  }, 
  data() {
    return {
      consts: consts,
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      showLoading: true,
      keywords: [],
      keyword: null,
      shortUrlHost: process.env.VUE_APP_SHORT_URL_HOST || 'https://wal.tw',
      fields: [
        {
          key: "title",
          label: "標題",
        },
        {
          key: "code",
          label: "短網址",
        },
        {
          key: "url",
          label: "目標網址",
        },
        {
          key: "created_at",
          label: "建立時間",
          formatter: (value) => {
            if (value) {
              return format(new Date(value), 'yyyy-MM-dd');
            }
            return '';
          }
        },
        {
          key: "actions",
          label: "操作",
        },
      ],
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
  },
  watch: {
    currentPage() {
      this.getShortUrls();
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.getShortUrls();
    },
    async getShortUrls() {
      this.showLoading = true;
      try {
        let params = {
          per_page: this.perPage,
          page: this.currentPage,
          keyword: this.keyword,
        };

        const {data} = await shortUrlApi.getShortUrls(this.organization, params);
        this.keywords = data.data;
        this.totalRows = data.meta.total;
      } catch (error) {
        console.error(error);
        if (error.status !== 401) {
          this.$swal.fire({
            title: "錯誤",
            type: "error",
            text: "取得關鍵字資料錯誤",
          });
        }
      }
      this.showLoading = false;
    },
    async deleteShortUrls(shortUrl) {
      await this.$swal
        .fire({
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>關鍵字：${shortUrl.code}</div>
              </div>
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          type: "warning",
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            let response = await shortUrlApi.removeShortUrl(this.organization, shortUrl);
            if (response.status === 200) {
              this.$swal.fire({
                title: "成功",
                type: "success",
                text: "成功刪除 " + shortUrl.name,
              });
              this.getShortUrls();
            } else {
              if (response.data.message) {
                this.$swal.fire({
                  title: '錯誤',
                  type: "error",
                  text: response.data.message,
                });
              }
            }
          }
        });
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker;
      return checker.check(permissions);
    },

    clipboard(value) {
      navigator.clipboard.writeText(value)
    }
  },
};
</script>

<style scoped>
.copy {
  cursor: pointer;
}

.qrcode {
  cursor: pointer;
}

.btn + .btn {
  margin-left: 0.5rem;
}

.vdp-datepicker {
  min-width: 15rem;
}
</style>
